var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.disabled},on:{"click":function($event){_vm.dialog = true}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.btnText))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.title))]),_c('v-spacer')],1),_c('main',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.years,"outlined":"","dense":""},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1),_c('ProjectsRegisterTab',{staticStyle:{"margin-top":"10px"},attrs:{"select-mode":true,"year":_vm.year},on:{"select":function($event){return _vm.$emit('select', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var offerExperts = ref.offerExperts;
var entry = ref.entry;
var passport = ref.passport;
var select = ref.select;
var selectMode = ref.selectMode;
return [_c('ProjectEntryWiringsForm',{attrs:{"offer-experts":offerExperts,"entry":entry,"passport":passport,"select-mode":selectMode},on:{"select":_vm.selectProject}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container v-if="apiLoaded">
    <h1>
      <span>Проводки</span>
      <div>
        <!-- <v-btn class="add-link" @click="$router.push('/newwiring/')"
          >Создать проводку</v-btn
        > -->
        <WiringDialog :resetKey="resetKey" @wiringSaved="reloadRegisterTab" title="Создать проводку"  >
          <template v-slot="{ disabled, attrs, on, click }">
            <v-btn
              :disabled="disabled"
              :bind="attrs"
              :on="on" 
              @click=click>
              Создать проводку</v-btn>
          </template>
        </WiringDialog>
      </div>
    </h1>
    <WRegisterTab :key="resetKey" :config="config" :selectMode="false" :storageKey="'wiringListHeadersRegister'"/>
    <!-- <Register :config="config"></Register> -->

    <!-- <section class="mt-6">
      <h2>Проводки</h2>
      <v-col cols="2">
        <v-select
          :items="years"
          outlined
          dense
          v-model="yearsFilter"
        ></v-select>
      </v-col> -->
    <!-- <table style="width: 100%">
        <thead>
          <tr>
            <th>
              <svg
                width="10"
                height="12"
                viewBox="0 0 10 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.31331 4.43333L2.37331 5.38L4.33331 7.33333L7.79331 3.88L6.85331 2.93333L4.33331 5.45333L3.31331 4.43333ZM8.33331 0H1.66665C0.933313 0 0.333313 0.6 0.333313 1.33333V12L4.99998 10L9.66665 12V1.33333C9.66665 0.6 9.06665 0 8.33331 0ZM8.33331 10L4.99998 8.54667L1.66665 10V1.33333H8.33331V10Z"
                  fill="#77809A"
                />
              </svg>
            </th>
            <th>id проводки</th>
            <th>сумма</th>
            <th>дата изм.</th>
            <th>план/факт</th>
            <th>нал/безнал</th>
            <th>отв-ный</th>
            <th>орг–ция</th>
            <th>доход / расход</th>
            <th>фонд</th>
            <th>статья</th>
            <th>контрагент</th>
            <th>год по бюджету</th>
            <th>проект</th>
            <th>договор</th>
            <th>автор записи</th>

            <th>примечание</th>
            <th></th>
          </tr>
        </thead>
        <tbody> -->
    <!--					<tr>-->
    <!--            <td></td>-->
    <!--						<td>2932753641</td>-->
    <!--						<td>1 000 000</td>-->
    <!--						<td>01.12.2020</td>-->
    <!--						<td>Факт</td>-->
    <!--						<td>Безнал</td>-->
    <!--						<td><a href="#">Краснов С.М.</a></td>-->
    <!--						<td><a href="#">АНО «НИСИПП»</a></td>-->
    <!--						<td>Расход</td>-->
    <!--						<td>Проектные расходы</td>-->
    <!--						<td>Сторонние соисполнители</td>-->
    <!--						<td><a href="#">ООО «НПП «КИБЕРГРУППА»</a></td>-->
    <!--						<td>2020</td>-->
    <!--						<td><a href="#">БизнесПодКлюч2020</a></td>-->
    <!--            <td><a href="#">Договор 01/25/62-20-2 ...</a></td>-->
    <!--            <td><a href="#">Краснов С.М.</a></td>-->
    <!--            <td>Примечание</td>-->
    <!--						<div class="action">-->
    <!--								<div>-->
    <!--									<a href="#">Редактировать</a>-->
    <!--									<a href="#">Удалить</a>-->
    <!--								</div>-->
    <!--							</div>-->
    <!--					</tr>-->
    <!--					<tr>-->
    <!--            <td></td>-->
    <!--						<td>2932753641</td>-->
    <!--						<td>1 000 000</td>-->
    <!--						<td>01.12.2020</td>-->
    <!--						<td>Факт</td>-->
    <!--						<td>Безнал</td>-->
    <!--						<td><a href="#">Краснов С.М.</a></td>-->
    <!--						<td><a href="#">АНО «НИСИПП»</a></td>-->
    <!--						<td>Расход</td>-->
    <!--						<td>Проектные расходы</td>-->
    <!--						<td>Сторонние соисполнители</td>-->
    <!--						<td><a href="#">ООО «НПП «КИБЕРГРУППА»</a></td>-->
    <!--						<td>2020</td>-->
    <!--						<td><a href="#">БизнесПодКлюч2020</a></td>-->
    <!--            <td><a href="#">Договор 01/25/62-20-2 ...</a></td>-->
    <!--            <td><a href="#">Краснов С.М.</a></td>-->
    <!--            <td>Примечание</td>-->
    <!--						<div class="action">-->
    <!--								<div>-->
    <!--									<a href="#">Редактировать</a>-->
    <!--									<a href="#">Удалить</a>-->
    <!--								</div>-->
    <!--							</div>-->
    <!--					</tr>-->
    <!--          <tr>-->
    <!--            <td></td>-->
    <!--						<td>2932753641</td>-->
    <!--						<td>1 000 000</td>-->
    <!--						<td>01.12.2020</td>-->
    <!--						<td>Факт</td>-->
    <!--						<td>Безнал</td>-->
    <!--						<td><a href="#">Краснов С.М.</a></td>-->
    <!--						<td><a href="#">АНО «НИСИПП»</a></td>-->
    <!--						<td>Расход</td>-->
    <!--						<td>Проектные расходы</td>-->
    <!--						<td>Сторонние соисполнители</td>-->
    <!--						<td><a href="#">ООО «НПП «КИБЕРГРУППА»</a></td>-->
    <!--						<td>2020</td>-->
    <!--						<td><a href="#">БизнесПодКлюч2020</a></td>-->
    <!--            <td><a href="#">Договор 01/25/62-20-2 ...</a></td>-->
    <!--            <td><a href="#">Краснов С.М.</a></td>-->
    <!--            <td>Примечание</td>-->
    <!--						<div class="action">-->
    <!--								<div>-->
    <!--									<a href="#">Редактировать</a>-->
    <!--									<a href="#">Удалить</a>-->
    <!--								</div>-->
    <!--							</div>-->
    <!--					</tr>-->
    <!--          <tr>-->
    <!--            <td></td>-->
    <!--						<td>2932753641</td>-->
    <!--						<td>1 000 000</td>-->
    <!--						<td>01.12.2020</td>-->
    <!--						<td>Факт</td>-->
    <!--						<td>Безнал</td>-->
    <!--						<td><a href="#">Краснов С.М.</a></td>-->
    <!--						<td><a href="#">АНО «НИСИПП»</a></td>-->
    <!--						<td>Расход</td>-->
    <!--						<td>Проектные расходы</td>-->
    <!--						<td>Сторонние соисполнители</td>-->
    <!--						<td><a href="#">ООО «НПП «КИБЕРГРУППА»</a></td>-->
    <!--						<td>2020</td>-->
    <!--						<td><a href="#">БизнесПодКлюч2020</a></td>-->
    <!--            <td><a href="#">Договор 01/25/62-20-2 ...</a></td>-->
    <!--            <td><a href="#">Краснов С.М.</a></td>-->
    <!--            <td>Примечание</td>-->
    <!--						<div class="action">-->
    <!--								<div>-->
    <!--									<a href="#">Редактировать</a>-->
    <!--									<a href="#">Удалить</a>-->
    <!--								</div>-->
    <!--							</div>-->
    <!--					</tr>-->
    <!--          <tr>-->
    <!--            <td></td>-->
    <!--						<td>2932753641</td>-->
    <!--						<td>1 000 000</td>-->
    <!--						<td>01.12.2020</td>-->
    <!--						<td>Факт</td>-->
    <!--						<td>Безнал</td>-->
    <!--						<td><a href="#">Краснов С.М.</a></td>-->
    <!--						<td><a href="#">АНО «НИСИПП»</a></td>-->
    <!--						<td>Расход</td>-->
    <!--						<td>Проектные расходы</td>-->
    <!--						<td>Сторонние соисполнители</td>-->
    <!--						<td><a href="#">ООО «НПП «КИБЕРГРУППА»</a></td>-->
    <!--						<td>2020</td>-->
    <!--						<td><a href="#">БизнесПодКлюч2020</a></td>-->
    <!--            <td><a href="#">Договор 01/25/62-20-2 ...</a></td>-->
    <!--            <td><a href="#">Краснов С.М.</a></td>-->
    <!--            <td>Примечание</td>-->
    <!--						<div class="action">-->
    <!--								<div>-->
    <!--									<a href="#">Редактировать</a>-->
    <!--									<a href="#">Удалить</a>-->
    <!--								</div>-->
    <!--							</div>-->
    <!--					</tr>-->
    <!--          <tr>-->
    <!--            <td></td>-->
    <!--						<td>2932753641</td>-->
    <!--						<td>1 000 000</td>-->
    <!--						<td>01.12.2020</td>-->
    <!--						<td>Факт</td>-->
    <!--						<td>Безнал</td>-->
    <!--						<td><a href="#">Краснов С.М.</a></td>-->
    <!--						<td><a href="#">АНО «НИСИПП»</a></td>-->
    <!--						<td>Расход</td>-->
    <!--						<td>Проектные расходы</td>-->
    <!--						<td>Сторонние соисполнители</td>-->
    <!--						<td><a href="#">ООО «НПП «КИБЕРГРУППА»</a></td>-->
    <!--						<td>2020</td>-->
    <!--						<td><a href="#">БизнесПодКлюч2020</a></td>-->
    <!--            <td><a href="#">Договор 01/25/62-20-2 ...</a></td>-->
    <!--            <td><a href="#">Краснов С.М.</a></td>-->
    <!--            <td>Примечание</td>-->
    <!--						<div class="action">-->
    <!--								<div>-->
    <!--									<a href="#">Редактировать</a>-->
    <!--									<a href="#">Удалить</a>-->
    <!--								</div>-->
    <!--							</div>-->
    <!--					</tr>-->
    <!--          <tr>-->
    <!--            <td></td>-->
    <!--						<td>2932753641</td>-->
    <!--						<td>1 000 000</td>-->
    <!--						<td>01.12.2020</td>-->
    <!--						<td>Факт</td>-->
    <!--						<td>Безнал</td>-->
    <!--						<td><a href="#">Краснов С.М.</a></td>-->
    <!--						<td><a href="#">АНО «НИСИПП»</a></td>-->
    <!--						<td>Расход</td>-->
    <!--						<td>Проектные расходы</td>-->
    <!--						<td>Сторонние соисполнители</td>-->
    <!--						<td><a href="#">ООО «НПП «КИБЕРГРУППА»</a></td>-->
    <!--						<td>2020</td>-->
    <!--						<td><a href="#">БизнесПодКлюч2020</a></td>-->
    <!--            <td><a href="#">Договор 01/25/62-20-2 ...</a></td>-->
    <!--            <td><a href="#">Краснов С.М.</a></td>-->
    <!--            <td>Примечание</td>-->
    <!--						<div class="action">-->
    <!--								<div>-->
    <!--									<a href="#">Редактировать</a>-->
    <!--									<a href="#">Удалить</a>-->
    <!--								</div>-->
    <!--							</div>-->
    <!--					</tr>-->

    <!-- <tr v-for="i in projectWInfoFiltered" v-bind:key="i.id">
            <template
              v-if="i.data.project ? i.data.project.id : '1' != projectId"
            >
              <td></td>

              <td>
                <router-link :to="`/wiringEdit/${i.id}`">
                  {{ i.id }}
                </router-link>
              </td>
              <td>{{ i.data.sum }}</td>
              <td>{{ i.data.paymentDate }}</td>
              <td>{{ i.data.wiringType == 1 ? 'План' : 'Факт' }}</td>
              <td>{{ i.data.paymentMethod == 1 ? 'Нал' : 'Безнал' }}</td>
              <td>
                <router-link :to="`/employee/${i.data.expert.id}/info`">{{
                  i.data.expert ? i.data.expert['fullName'] : ''
                }}</router-link>
              </td>
              <td>
                <router-link :to="`/counterparty/${i.data.organization.id}`">{{
                  i.data.organization ? i.data.organization['shortName'] : ''
                }}</router-link>
              </td>
              <td>
                {{
                  i.data.wiringTypeDir
                    ? wiringTypeDirDict[i.data.wiringTypeDir]
                    : ''
                }}
              </td>
              <td>{{ i.data.funds ? i.data.funds.fund : '' }}</td>
              <td>{{ i.data.article ? i.data.article['article'] : '' }}</td>
              <td>
                <router-link :to="`/counterparty/${i.data.contractor.id}`">{{
                  i.data.contractor ? i.data.contractor['fullName'] : ''
                }}</router-link>
              </td>
              <td>{{ i.data.budgetYear }}</td>
              <td>
                <router-link
                  :to="`/project/${i.data.project ? i.data.project.id : ''}`"
                  >{{
                    i.data.project
                      ? i.data.project.name
                        ? i.data.project.name
                        : i.data.project
                      : ''
                  }}</router-link
                >
              </td>
              <td>
                <router-link
                  :to="
                    i.data.contractId
                      ? `/contract/${i.data.contractId.id}/info`
                      : '#'
                  "
                  >{{
                    i.data.contractId ? i.data.contractId['contractNumber'] : ''
                  }}</router-link
                >
              </td>
              <td><a href="#">Краснов С.М.</a></td>
              <td>{{ i.data.comment ? i.data.comment : '' }}</td>
              <div class="action">
                <div>
                  <a href="#">Просмотр</a>
                  <a :href="`/wiringEdit/${i.id}`">Редактировать</a>
                  <a href="#">Удалить</a>
                </div>
              </div>
            </template>
          </tr>
        </tbody>
      </table>
    </section> -->
  </v-container>
</template>

<script>
import { entriesRegisterConfig } from './register/RegisterConfigs';
// import Register from './register/Register.vue';
import WRegisterTab from './register/WRegisterTab.vue';
import WiringDialog from './wirings/WiringDialog.vue';
import { wiringTypeDirDict } from '@/components/register/WireDict';
// import { wDec, wGet } from '@/modules/Wirings';

export default {
  name: 'EntriesComponent',
  props: {},
  components: { WRegisterTab, WiringDialog },
  data() {
    return {
      years: [2021, 2022, 2023],
      yearsFilter: null,
      projectWInfo: [],
      projectWInfoFiltered: [],
      projectWenc: [],
      projectId: null,
      apiLoaded: false,
      wiringTypeDirDict: wiringTypeDirDict,
      filtersOpened: false,
      config: entriesRegisterConfig,
      resetKey: Math.random()
      //   {
      //     tabs: [
      //       {
      //         title: 'Проводки',
      //         config: entriesRegisterConfig,
      //       },
      //     ],
      //   },
    };
  },
  methods: {
    reloadRegisterTab() {
      this.resetKey = Math.random();
    }
  },
  watch: {
    // yearsFilter() {
    //   if (this.yearsFilter != null) {
    //     this.projectWInfoFiltered = this.projectWInfo.filter(
    //       (item) => item.data.budgetYear == this.yearsFilter
    //     );
    //   } else {
    //     this.projectWInfoFiltered = this.projectWInfo.filter((item) => item);
    //   }
    //   console.log('filter', this.projectWInfoFiltered);
    // },
  },
  async beforeMount() {
    // this.projectWenc = await wGet();
    // this.projectWenc23 = await wGet(2023);
    // this.projectWInfo23 = await wDec(this.projectWenc23);
    // this.projectWInfo = await wDec(this.projectWenc);
    // this.projectWInfo = [...this.projectWInfo, ...this.projectWInfo23];

    // this.projectWInfo.sort((a, b) => a.id - b.id);
    // console.log('projectWInfo', this.projectWInfo);
    // this.projectWInfoFiltered = this.projectWInfo.filter((item) => item);
    // console.log('projectWInfoFiltered', this.projectWInfoFiltered);
    this.apiLoaded = true;
  },
};
</script>

<template>
    <div>
        <WiringsHeadersList @selectedHeaders="saveHeaders" :headers="headers" :storageKey="storageKey" />
        <v-data-table
            :no-data-text="'Проводок не найдено'"
            :loading-text="'Загружаем...'"
            :footer-props="{
                showFirstLastPage: true,
                'items-per-page-text':'Проводок на странице',
                'items-per-page-all-text': 'Все',                
                'page-text': ``
            }"
            disable-sort 
            :headers="enabledHeaders" 
            :items="wiringsItems"
            :loading="loading"
            :items-per-page="15" 
            @click:row="(wData) => $router.push(`/wiring/${wData.id}`)"
        >
            <template v-slot:item.actions="{ item }" >
                <div class="d-flex">
                    <WiringDialog title="Редактировать проводку" :wData="getWiringData(item.id)">
                        <template v-slot="{ disabled, attrs, on, click }">
                            <v-icon
                            small
                            :disabled="disabled"
                            :bind="attrs"
                            :on="on" 
                            @click.stop="click">
                            mdi-pencil</v-icon>
                        </template>
                    </WiringDialog>
                    <v-icon
                        class="ml-1"
                        small
                        @click.stop="handleClickDeleteIcon(item)"
                        >
                        mdi-delete
                    </v-icon>
                </div>
            </template>
        </v-data-table>    
        <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
                <v-card-title class="text-h5">Вы действительно хотите удалить Проводку?</v-card-title>
                <v-card-actions>
                    <v-btn color="blue darken-1" @click="dialogDelete = false">Отмена</v-btn>
                    <v-btn color="blue darken-1" @click="deleteWiring()">ОК</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { wiringTypeDirDict, wiringType } from '@/components/register/WireDict';
import { wDelete } from '@/modules/Wirings';
import WiringsHeadersList from './WiringsHeadersList';
import { headers } from './headers.js'
import WiringDialog from '@/components/wirings/WiringDialog';
import { localStorage } from '@/modules/storage.js'

export default {
    name: 'WiringsTable',
    props: ['wirings', 'loading', 'storageKey'],
    components: { WiringsHeadersList, WiringDialog },
    data() {
        return {
            dialogDelete: false,
            selectedId: 1,
            headers: localStorage.get(this.storageKey) || headers[this.storageKey]
        }
    },
    methods: {
        saveHeaders(headers) {
            this.headers = JSON.parse(JSON.stringify(headers));
        },
        handleClickDeleteIcon(wData) {            
            this.dialogDelete = true; 
            this.selectedId = wData.id;
        },
        async deleteWiring() {
            const res = await wDelete(this.selectedId);
            if(res === 200) {
                this.$emit('removeEntry', this.selectedId);
                this.dialogDelete = false;
            }
        },
        getWiringData(id) {
            return this.wirings.find((wData) => wData.id === id);
        }
    },
    computed: {        
        enabledHeaders() {
            return this.headers.filter(({enabled}) => enabled);
        },
        wiringsItems() {
            return this.wirings.map((wiring) => {
                const {sum, article, fund, paymentDate, paymentMethod, budgetYear, contractor, organization, type, typeDir, project, expert, contract, note, createDate, contribution, accountNumber } = wiring.data;
                const formatter = new Intl.NumberFormat("ru", { style: "currency",currency: "RUB" });
                return {
                id: wiring.id,
                sum: formatter.format(sum) || '-',
                projectName: project?.name || '-',
                paymentDate: paymentDate || '-',
                paymentMethod: paymentMethod?.text || '-',
                budgetYear: budgetYear || '-',
                note: note || '-',
                contract: contract?.number || '-',
                contractor: contractor?.shortName || contractor?.fullName ||  '-',
                organization: organization?.shortName || '-',
                createDate: createDate ||  '-',
                type: wiringType?.[type] || '-',
                expert: expert?.fullName || '-',
                typeDir: `${wiringTypeDirDict[typeDir].title} - ${fund?.fund || '-'} - ${article?.article || '-'}`,
                contribution: contribution || '-',
                accountNumber: accountNumber || '-'
            }})
        },
        
    },
    beforeMount() {
    }
}
</script>

<style scoped>
    .v-data-table >>> tr > td {
        font-size: 12px !important;
    }
    .v-data-table >>> tr > th,
    .v-data-table >>> tr > td {
        padding: 10px !important;
    }
    .v-data-table >>> tbody > tr:hover {
        cursor: pointer;
    }
</style>
<template>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
    >
    <template v-slot:activator="{ on, attrs }">
        <slot
            :disabled="disabled"
            :bind="attrs"
            :on="on" 
            :click=openDialog>
                </slot>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <main>
            <WiringForm 
              :oldWData="wData" 
              @wiringSaved="handleWiringSaved" 
              @select="(event) => this.$emit('select', event)" 
              :project="project"
              :notSaveWiring="notSaveWiring"
              :key="resetKey" 
            ></WiringForm>
        </main>
      </v-card>
    </v-dialog>
</template>

<script>
import WiringForm from '@/components/wirings/WiringForm'

export default {
    name: 'AddWiringDialog',
    components: { WiringForm },
    props: ['title', 'disabled', 'btnText', 'project', 'projectID', 'resetKey', 'wData', 'notSaveWiring'],
    data() {
        return {
            dialog: false,
        }
    },
    methods: {
      handleWiringSaved() {
        this.dialog = false;
        this.$emit('wiringSaved');
      },
      openDialog() {
        this.dialog = true;
      }
    },
    watch: {
        resetKey() {
            this.dialog = false;
        }
    }
}
</script>
export const headers = {
    'wiringListHeadersRegister':[
    {
        text: 'ID',
        value: 'id',
        width: 30,
        enabled: true
    },
    {
        text: 'Сумма',
        value: 'sum',
        enabled: true
    },
    {
        text: 'Проект',
        value: 'projectName',
        width: 70,
        enabled: true
    },
    {
        text: 'Тип проводки',
        value: 'type',
        enabled: true
    },
    {
        text: 'Способ оплаты',
        value: 'paymentMethod',
        enabled: true
    },
    {
        text: 'Год по бюджету',
        value: 'budgetYear',
        enabled: true
    },
    {
        text: 'Организация',
        value: 'organization',
        enabled: true
    },
    
    {
        text: 'Доход/расход - фонд - статья',
        value: 'typeDir',
        width: 250,
        enabled: true
    },
    {
        text: 'Ответственный',
        value: 'expert',
        enabled: true
    },
    {
        text: 'Договор',
        value: 'contract',
        enabled: false
    },
    {
        text: 'Примечание',
        value: 'note',
        enabled: false,
    },
    {
        text: 'Дата создания',
        value: 'createDate',
        enabled: false
    },
    {
        text: 'Контрагент',
        value: 'contractor',
        enabled: true
    },
    {
        text: 'Дата оплаты',
        value: 'paymentDate',
        enabled: true
    },
    {
        text: 'Номер счёта',
        value: 'accountNumber',
        enabled: false
    },
    {
        text: 'Вклад',
        value: 'contribution',
        enabled: false
    },
    { 
        text: '', 
        value: 'actions', 
        sortable: false,
        enabled: true,
        reserved: true
    }
],
'wiringListHeadersProject':[
    {
        text: 'ID',
        value: 'id',
        width: 30,
        enabled: true
    },
    {
        text: 'Сумма',
        value: 'sum',
        enabled: true
    },
    {
        text: 'Проект',
        value: 'projectName',
        width: 70,
        enabled: false
    },
    {
        text: 'Тип проводки',
        value: 'type',
        enabled: true
    },
    {
        text: 'Способ оплаты',
        value: 'paymentMethod',
        enabled: true
    },
    {
        text: 'Год по бюджету',
        value: 'budgetYear',
        enabled: false
    },
    {
        text: 'Дата оплаты',
        value: 'paymentDate',
        enabled: true
    },
    {
        text: 'Организация',
        value: 'organization',
        enabled: true
    },
    
    {
        text: 'Доход/расход - фонд - статья',
        value: 'typeDir',
        width: 250,
        enabled: true
    },
    {
        text: 'Договор',
        value: 'contract',
        enabled: true
    },
    {
        text: 'Ответственный',
        value: 'expert',
        enabled: true
    },
    {
        text: 'Примечание',
        value: 'note',
        enabled: false,
    },
    {
        text: 'Дата создания',
        value: 'createDate',
        enabled: false
    },
    {
        text: 'Контрагент',
        value: 'contractor',
        enabled: true
    },
    {
        text: 'Номер счёта',
        value: 'accountNumber',
        enabled: false
    },
    {
        text: 'Вклад',
        value: 'contribution',
        enabled: false
    },
    { 
        text: '', 
        value: 'actions', 
        sortable: false,
        enabled: true,
        reserved: true
    }
]
};
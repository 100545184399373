<template>
    <div class="mb-3">
        <v-btn small color="blue darken-1" @click="dialogHeaders = true">Настройка списка полей</v-btn>
        <v-dialog v-model="dialogHeaders" max-width="600px">
            <v-card>
                <v-card-title>Редактирование состава полей</v-card-title>
                <v-card-text>
                    <ul class="list">
                        <li v-for="(header, index) in filteredLocalHeaders" :key="header.value">
                            <div class="d-flex">
                                <v-checkbox :label="header.text" v-model="header.enabled"></v-checkbox>
                                <div class="ml-auto">
                                    <v-btn @click="toDown(index)" text small><v-icon dense>mdi-arrow-down-thin</v-icon></v-btn>
                                    <v-btn @click="toUp(index)" text small><v-icon dense>mdi-arrow-up-thin</v-icon></v-btn>
                                </div>
                            </div>
                            <v-divider class="mb-2"></v-divider>
                        </li>
                        
                    </ul>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="cancel">Отмена</v-btn>
                    <v-btn @click="clearHeaders">Сбросить</v-btn>
                    <v-btn @click="saveHeaders">Применить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Vue from 'vue'
import { headers } from './headers.js'
import { localStorage } from '@/modules/storage.js'

export default {
    name: 'WiringsHeadersList',
    props: ['headers', 'storageKey'],
    data() {
        return {
            dialogHeaders: false,
            defaultHeaders: headers[this.storageKey],
            localHeaders: localStorage.get(this.storageKey) || JSON.parse(JSON.stringify(this.headers)),
        }
    },
    methods: {
        saveHeaders() {
            this.$emit('selectedHeaders', this.localHeaders);
            console.log(this.storageKey);
            localStorage.set(this.storageKey, this.localHeaders)
            this.dialogHeaders = false;
        },
        clearHeaders() {
            this.localHeaders = JSON.parse(JSON.stringify(this.defaultHeaders));
            localStorage.remove(this.storageKey)
        },
        cancel() {
            this.dialogHeaders = false;
            this.localHeaders = JSON.parse(JSON.stringify(this.headers))
        },
        toUp(index) {
            if (index <= 0 || index >= this.localHeaders.length) {
                return;
            }
            const temp = this.localHeaders[index];
            const leftNeighbor = this.localHeaders[index - 1];

            Vue.set(this.localHeaders, index, leftNeighbor);
            Vue.set(this.localHeaders, index - 1, temp);
        },
        toDown(index) {
            if (index < 0 || index >= this.localHeaders.length - 1) {
                return;
            }
            const temp = this.localHeaders[index];
            const leftNeighbor = this.localHeaders[index + 1];

            Vue.set(this.localHeaders, index, leftNeighbor);
            Vue.set(this.localHeaders, index + 1, temp);
        }
    },
    computed: {
        filteredLocalHeaders() {
            return this.localHeaders.filter((item) => !item.reserved)
        }
    },
    beforeMount() {
    }
}
</script>

<style scoped>
    .list {
        list-style-type: none;
        padding: 0;
    }
</style>
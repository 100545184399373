<template>
  <div class="card" @click="$emit('select', entry)">
    <div>{{ entry.shortName }}</div>
    <div>{{ entry.fullName }}</div>
    <div>{{ entry.year }}</div>
    <div>{{ entry.expert }}</div>
    <div>{{ entry.contractor.shortName || '-' }}</div>
  </div>
      <!-- <div class="project-entry">
        <div class="project-header">
          <div>
            <h3>{{ entry.number }}</h3>
            <h4>
              {{ entry.fullName || entry.name }}
            </h4>
          </div>
          <div>{{ entry.status ? projectStatusDict[entry.status] : '-' }}</div>
        </div>
        <div v-if="passport != undefined" class="project-wrap">
          <div class="project-directions">
            <div class="project-moreInfo">
              <div>
                <h6>Сумма контрактов (план/факт)</h6>
                <p>
                  {{
                    passport &&
                    passport.sum.contractSum &&
                    passport.sum.contractSum.plan
                      ? passport.sum.contractSum.plan.toLocaleString('ru') + ' ₽'
                      : '&mdash;'
                  }}
                </p>
                <p>
                  {{
                    passport &&
                    passport.sum.contractSum &&
                    passport.sum.contractSum.fact
                      ? passport.sum.contractSum.fact.toLocaleString('ru') + ' ₽'
                      : '&mdash;'
                  }}
                </p>
              </div>
              <div>
                <h6>Сумма vi(план/факт)</h6>
                <p>
                  {{
                    passport &&
                    passport.sum.viContractSum &&
                    passport.sum.viContractSum.plan
                      ? passport.sum.viContractSum.plan.toLocaleString('ru') +
                        ' ₽'
                      : '&mdash;'
                  }}
                </p>
                <p>
                  {{
                    passport &&
                    passport.sum.viContractSum &&
                    passport.sum.viContractSum.fact
                      ? passport.sum.viContractSum.fact.toLocaleString('ru') +
                        ' ₽'
                      : '&mdash;'
                  }}
                </p>
              </div>
              <div>
                <h6>Сумма pri(план/факт)</h6>
                <p>
                  {{
                    passport &&
                    passport.sum.priProjectProfit &&
                    passport.sum.priProjectProfit.plan
                      ? passport.sum.priProjectProfit.plan.toLocaleString('ru') +
                        ' ₽'
                      : '&mdash;'
                  }}
                </p>
                <p>
                  {{
                    passport &&
                    passport.sum &&
                    passport.sum.priProjectProfit &&
                    passport.sum.priProjectProfit.fact
                      ? passport.sum.priProjectProfit.fact.toLocaleString('ru') +
                        ' ₽'
                      : '&mdash;'
                  }}
                </p>
              </div>
              <div>
                <h6>доля в прибыли(план/факт)</h6>
                <p>
                  {{
                    passport &&
                    passport.sum &&
                    passport.sum.contractSum &&
                    passport.sum.priProjectProfit &&
                    passport.sum.contractSum.plan &&
                    passport.sum.priProjectProfit.plan
                      ? (
                          (passport.sum.priProjectProfit.plan /
                            passport.sum.contractSum.plan) *
                          100
                        ).toFixed(2) + ' %'
                      : '&mdash;'
                  }}
                </p>
                <p>
                  {{
                    passport &&
                    passport.sum &&
                    passport.sum.contractSum &&
                    passport.sum.priProjectProfit &&
                    passport.sum.contractSum.fact &&
                    passport.sum.priProjectProfit.fact
                      ? (
                          (passport.sum.priProjectProfit.fact /
                            passport.sum.contractSum.fact) *
                          100
                        ).toFixed(2) + ' %'
                      : '&mdash;'
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="project-info">
            <div>
              <h6>Руководитель</h6>
              <p v-if="entry.offerExpert">
                {{ offerExpertName }}
              </p>
            </div>
          </div>
        </div>
      </div> -->
  </template>
  <script>
  import { projectStatusDict, supMarketDict } from '@/modules/NSI';
  import { dictToObject, getExpertShortName } from '@/modules/CommonUtils';
  
  export default {
    name: 'ApplicationEntry',
    props: ['entry', 'offerExperts', 'passport'],
    data() {
      return {
        projectStatusDict: dictToObject(projectStatusDict),
        supMarketDict: dictToObject(supMarketDict),
      };
    },
    computed: {
      offerExpertName() {
        return getExpertShortName(
          this.offerExperts.find((e) => e.id === this.entry.offerExpert)
        );
      },
    },
    beforeMount() {
      // console.log('passport', this.passport);
      console.log(this.entry, 'entry')
    },
  };
  </script>

<style scoped>
  .card {
    display: flex;
  }
  .card div {
    flex: 1;
  }
</style>
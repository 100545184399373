var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('WiringsHeadersList',{attrs:{"headers":_vm.headers,"storageKey":_vm.storageKey},on:{"selectedHeaders":_vm.saveHeaders}}),_c('v-data-table',{attrs:{"no-data-text":'Проводок не найдено',"loading-text":'Загружаем...',"footer-props":{
            showFirstLastPage: true,
            'items-per-page-text':'Проводок на странице',
            'items-per-page-all-text': 'Все',                
            'page-text': ""
        },"disable-sort":"","headers":_vm.enabledHeaders,"items":_vm.wiringsItems,"loading":_vm.loading,"items-per-page":15},on:{"click:row":function (wData) { return _vm.$router.push(("/wiring/" + (wData.id))); }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('WiringDialog',{attrs:{"title":"Редактировать проводку","wData":_vm.getWiringData(item.id)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var disabled = ref.disabled;
        var attrs = ref.attrs;
        var on = ref.on;
        var click = ref.click;
return [_c('v-icon',{attrs:{"small":"","disabled":disabled,"bind":attrs,"on":on},on:{"click":function($event){$event.stopPropagation();return click.apply(null, arguments)}}},[_vm._v(" mdi-pencil")])]}}],null,true)}),_c('v-icon',{staticClass:"ml-1",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleClickDeleteIcon(item)}}},[_vm._v(" mdi-delete ")])],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Вы действительно хотите удалить Проводку?")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1"},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Отмена")]),_c('v-btn',{attrs:{"color":"blue darken-1"},on:{"click":function($event){return _vm.deleteWiring()}}},[_vm._v("ОК")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
    >
    <template v-slot:activator="{ on, attrs }">
        <v-btn 
            :disabled="disabled"
            v-bind="attrs"
            v-on="on"
            @click='dialog = true'>
                {{ btnText }}</v-btn>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <main>
            <!-- <ProjectTable :key="resetKey" @selectProject="selectProject"></ProjectTable> -->
            <!-- <RegisterTab style="margin-top: 85px" :key="resetKey" :config="config" @selectProject="selectProject" /> -->
            
              <v-col cols="3">
                <v-select
                :items="years"
                v-model="year"
                outlined
                dense
                ></v-select>
              </v-col>
            
            <ProjectsRegisterTab
              style="margin-top: 10px"
              :select-mode="true"
              :year="year"
              @select="$emit('select', $event)"
            >
            <template v-slot="{ offerExperts, entry, passport, select, selectMode }">
              <ProjectEntryWiringsForm
                :offer-experts="offerExperts"
                :entry="entry"
                :passport="passport"
                @select="selectProject"
                :select-mode="selectMode"
              ></ProjectEntryWiringsForm>
            </template>
          </ProjectsRegisterTab>
        </main>
      </v-card>
    </v-dialog>
</template>

<script>
// import ProjectTable from '@/components/projects/ProjectsTable';
import ProjectsRegisterTab from '@/components/register/ProjectsRegisterTab.vue';
import ProjectEntryWiringsForm from '@/components/projects/ProjectEntryWiringsForm.vue';
import dayjs from 'dayjs';

export default {
    name: 'AddProjectDialog',
    components: { ProjectsRegisterTab, ProjectEntryWiringsForm },
    props: ['title', 'disabled', 'btnText', 'project', 'resetKey'],
    data() {
        return {
            dialog: false,
            year: dayjs().year()
        }
    },
    methods: {
      selectProject(project) {
        this.$emit('selectProject', project);
        this.dialog = false
      }
    },
    computed: {
      years() {
        const currentYear = dayjs().year();
        return [currentYear - 1, currentYear, currentYear + 1, currentYear + 2];
      }
    },
    watch: {
        resetKey() {
            this.dialog = false
        }
    }
}
</script>
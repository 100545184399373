class Storage {
    constructor() {}
    set(key, value) {
        const string = JSON.stringify(value);
        window.localStorage.setItem(key, string);
    }
    get(key) {
        const string = window.localStorage.getItem(key);
        return JSON.parse(string);
    }
    remove(key) {
        window.localStorage.removeItem(key);
    }
}

export const localStorage = new Storage();